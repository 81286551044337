import * as services from '../services/serviceVolume';
import * as servicesOptimise from '../services/serviceOptimise';
import _ from 'lodash';
import { fetchError, fetchStart, fetchSuccess } from './common/actionFetch';

export const GET_VOLUME_CATEGORY = 'GET_VOLUME_CATEGORY';
export const getVolumeCategory = (type) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VOLUME_CATEGORY));
    services
      .getVolumeCategory(type)
      .then((response) => {
        dispatch({
          type: GET_VOLUME_CATEGORY,
          payload: response.data,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_VOLUME_CATEGORY)))
      .catch(() => dispatch(fetchError(GET_VOLUME_CATEGORY)));
  };
};

export const GET_TENANT_VESSELS = 'GET_TENANT_VESSELS';
export const getVessels = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_TENANT_VESSELS));
    services
      .getVessels(data)
      .then((response) => {
        dispatch({
          type: GET_TENANT_VESSELS,
          payload: response.data.rows,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_TENANT_VESSELS)))
      .catch(() => dispatch(fetchError(GET_TENANT_VESSELS)));
  };
};

export const GET_PARTNER_REGIONS = 'GET_PARTNER_REGIONS';
export const getPartnerRegions = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_PARTNER_REGIONS));
    services
      .getPartnerRegions(data)
      .then((response) => {
        dispatch({
          type: GET_PARTNER_REGIONS,
          payload: response.data,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_PARTNER_REGIONS)))
      .catch(() => dispatch(fetchError(GET_PARTNER_REGIONS)));
  };
};

export const GET_LOCATIONS = 'GET_LOCATIONS';
export const getLocationsByRegion = (additionalParam) => {
  const extraParam = _.map(additionalParam, (item) => `&${item.properties}=${item.value}`);
  return (dispatch) => {
    dispatch(fetchStart(GET_LOCATIONS));
    servicesOptimise
      .getDropdown('location', extraParam)
      .then((response) =>
        dispatch({
          type: GET_LOCATIONS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LOCATIONS)))
      .catch(() => dispatch(fetchError(GET_LOCATIONS)));
  };
};

export const POST_FUEL_VOLUME_DATA = 'POST_FUEL_VOLUME_DATA';
export const postFuelVolumeData = (data) => {
  return async (dispatch) => {
    dispatch(fetchStart(POST_FUEL_VOLUME_DATA));
    return services
      .postFuelVolumeData(data)
      .then((response) => {
        dispatch(fetchSuccess(POST_FUEL_VOLUME_DATA));
        return response;
      })
      .catch((error) => {
        dispatch(fetchError(POST_FUEL_VOLUME_DATA));
        return error.response.data;
      });
  };
};

export const FETCH_PRE_SIGNED_URLS = 'FETCH_PRE_SIGNED_URLS';
export const fetchPresignedUrls = (data) => {
  return async (dispatch) => {
    dispatch(fetchStart(POST_FUEL_VOLUME_DATA));
    return services
      .fetchPresignedUrls(data)
      .then((response) => {
        dispatch(fetchSuccess(POST_FUEL_VOLUME_DATA));
        return response;
      })
      .catch((error) => {
        dispatch(fetchError(POST_FUEL_VOLUME_DATA));
        return error.response.data;
      });
  };
};

export const GET_VOLUME_BY_ID = 'GET_VOLUME_BY_ID';
export const getVolumeById = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VOLUME_BY_ID));
    services
      .getVolumeById(data)
      .then((response) => {
        dispatch({
          type: GET_VOLUME_BY_ID,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_VOLUME_BY_ID)))
      .catch(() => dispatch(fetchError(GET_VOLUME_BY_ID)));
  };
};

export const PUT_VOLUME = 'PUT_VOLUME';
export const putVolume = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_VOLUME));
    services
      .putVolume(data)
      .then((response) => {
        dispatch({
          type: PUT_VOLUME,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(PUT_VOLUME)))
      .catch(() => dispatch(fetchError(PUT_VOLUME)));
  };
};

export const GET_FUEL_CAPPING_DATA = 'GET_FUEL_CAPPING_DATA';
export const getFuelCappingData = (param) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CAPPING_DATA));
    services
      .getFuelCappingData(param)
      .then((response) => {
        dispatch({
          type: GET_FUEL_CAPPING_DATA,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CAPPING_DATA)))
      .catch(() => dispatch(fetchError(GET_FUEL_CAPPING_DATA)));
  };
};

export const SET_STATE_VOLUME_PAGE = 'SET_STATE_VOLUME_PAGE';
export const setStateVolumePage = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: SET_STATE_VOLUME_PAGE,
      payload: { [key]: value },
    });
  };
};

export const DEACTIVATE_VOLUME = 'DEACTIVATE_VOLUME';
export const deactivateVolume = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(DEACTIVATE_VOLUME));
    return services
      .deactivateVolume(data)
      .then((response) => {
        dispatch(fetchSuccess(DEACTIVATE_VOLUME));
        return response;
      })
      .catch((error) => {
        dispatch(fetchError(POST_FUEL_VOLUME_DATA));
        return error.response.data;
      });
  };
};

export const GET_VESSEL_STANDARD_RATE = 'GET_VESSEL_STANDARD_RATE';
export const getVesselStandardRate = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_STANDARD_RATE));
    return services
      .getVesselStandardRate(data)
      .then((response) => {
        dispatch({
          type: GET_VESSEL_STANDARD_RATE,
          payload: response?.result,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_VESSEL_STANDARD_RATE)))
      .catch((error) => {
        dispatch(fetchError(GET_VESSEL_STANDARD_RATE));
        return error.response.data;
      });
  };
};

export const GET_METER_TICKET = 'GET_METER_TICKET';
export const getMeterTicket = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_METER_TICKET));
    return services
      .getMeterTicket(data)
      .then((response) => {
        dispatch({
          type: GET_METER_TICKET,
          payload: response?.data,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_METER_TICKET)))
      .catch((error) => {
        dispatch(fetchError(GET_METER_TICKET));
        return error.response.data;
      });
  };
}