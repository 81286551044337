import _ from 'lodash';
import queryString from 'query-string';
import WMSCapabilities from 'wms-capabilities';
import { v4 as uuidv4 } from 'uuid';

//! Try not to use the functions below, common function should implemented in the lambdaServer.js
export const determineResponseResult = (response) => {
  if (_.get(response.data, 'status.code') !== 200) {
    // throw new Error(response.data.data)
  }
  return response.data.data;
};

export const determineLambdaResponseResult = (response) => {
  if (_.get(response.data, 'status.code') !== 200) {
    // throw new Error(response.data.data)
  }
  return response.data;
};

export const checkStatusCode = (response) => {
  if (_.get(response.data, 'status.code') !== 200) {
    throw new Error(response.data.data);
  }
  return response.data.data;
};

export function getUrlKeyValue(key) {
  const url = new URL(window.location.href);
  return url.searchParams.get(key);
}

export function getUrlHashValueBykey(key) {
  const url = new URL(window.location.href);
  const hashObj = queryString.parse(url.hash);
  return _.get(hashObj, key);
}

export function getRootUrl(url = window.location.href) {
  const path = url.split('/');
  path.length = 3;
  return path.join('/');
}

export function objectToQuery(obj) {
  return '?' + new URLSearchParams(obj).toString();
}

export const getWMSCapabilities = (bundle) =>
  new Promise((resolve, reject) => {
    let Full_WMS_XML = {};
    let Latest_WMS = {};
    let uri = `${process.env.REACT_APP_SPIRE_WMS_URL}?service=WMS&request=GetCapabilities&product=sof-d`;
    uri += '&bundle=' + bundle;
    uri += '&spire-api-key=' + process.env.REACT_APP_SPIRE_API_KEY;
    fetch(uri)
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          return reject(`WMS capabilities api return with ${response.status}`);
        }
        Full_WMS_XML = { ...Full_WMS_XML, bundle };
        return response.text();
      })
      .then((str) => {
        if (!str) {
          return reject();
        }
        let parsed = new WMSCapabilities(str).toJSON();
        return parsed;
      })
      .then((data) => {
        if (_.isEmpty(data?.version)) {
          return reject('WMS data is empty!');
        }
        let capabilities = data['Capability'];
        let days = capabilities['Layer']['Layer'][0]['Layer'];
        let latest_date = {
          text: '',
          epoch: 0,
        };
        days.forEach((day) => {
          let dateText = day['Title'];
          Full_WMS_XML[bundle] = { ...Full_WMS_XML[bundle], dateText };
          let hours = day['Layer'];
          hours.forEach((hour) => {
            let hourText = hour['Title'];
            Full_WMS_XML[bundle][dateText] = { ...Full_WMS_XML[bundle][dateText], hourText };
            let variables = hour['Layer'];
            variables.forEach((variable) => {
              let bounding_boxes = Object.fromEntries(
                variable['BoundingBox'].map((bbox) => [bbox['crs'], bbox['extent']])
              );
              let name = variable['Name'];
              let displayName = variable['Title'];
              let dimensions = variable['Dimension'];
              if (dimensions === undefined) {
                reject();
              }
              let times = [];
              dimensions.forEach((dimension) => {
                if (dimension['name'] === 'time') {
                  times = dimension.values.split(',');
                }
              });
              let styleOptions = variable['Style'];
              let stylesAndLegends = {};
              styleOptions.forEach((style) => {
                let styleName = style['Name'];
                let legend = style['LegendURL'];
                if (legend) {
                  let legendURL = legend[0]['OnlineResource'];
                  stylesAndLegends[styleName] = legendURL + '&spire-api-key=' + process.env.REACT_APP_SPIRE_API_KEY;
                } else {
                  stylesAndLegends[styleName] = 'none';
                }
              });
              Full_WMS_XML[bundle][dateText][hourText] = {
                ...Full_WMS_XML[bundle][dateText][hourText],
                displayName,
              };
              Full_WMS_XML[bundle][dateText][hourText][displayName] = {
                name: name,
                title: displayName,
                styles: stylesAndLegends,
                times: times,
                bundle: bundle,
                bounding_boxes: bounding_boxes,
              };
              if (times.length >= 50) {
                let epochTime = datestringToEpoch(dateText);
                if (epochTime > latest_date['epoch']) {
                  latest_date['text'] = dateText;
                  latest_date['epoch'] = epochTime;
                }
              }
            });
          });
        });
        let latest_forecast = Full_WMS_XML[bundle][latest_date['text']];
        let issuance_times = Object.keys(latest_forecast);
        let forecast = null;
        if (issuance_times.indexOf('18') !== -1) {
          let latest = latest_forecast['18'];
          let times = Object.values(latest)[1]['times'];
          if (times.length >= 50) {
            forecast = latest;
          }
        }
        if (forecast === null && issuance_times.indexOf('12') !== -1) {
          let latest = latest_forecast['12'];
          let times = Object.values(latest)[1]['times'];
          if (times.length >= 50) {
            forecast = latest;
          }
        }
        if (forecast === null && issuance_times.indexOf('06') !== -1) {
          let latest = latest_forecast['06'];
          let times = Object.values(latest)[1]['times'];
          if (times.length >= 50) {
            forecast = latest;
          }
        }
        if (forecast === null && issuance_times.indexOf('00') !== -1) {
          let latest = latest_forecast['00'];
          forecast = latest;
        }
        let options = Object.keys(forecast);
        options.forEach(function (opt) {
          Latest_WMS = { ...Latest_WMS };
          Latest_WMS[opt] = forecast[opt];
        });
        resolve(Latest_WMS);
      })
      .catch((e) => console.error(e));
  });

function datestringToEpoch(ds) {
  let year = ds.substring(0, 4);
  let month = ds.substring(4, 6);
  let day = ds.substring(6, 8);
  let datestring = year + '-' + month + '-' + day;
  return new Date(datestring).getTime();
}

export const getKeyByValue = (obj, value) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const getTypeIdByKey = (mappingArr = [], key = '') => {
  return mappingArr.find((item) => item.objKey === key)?.typeId;
};

export const getNumber = (input) => {
  if (typeof input === 'string') {
    input = input.replaceAll(',', '');
  }
  const raw = parseFloat(input ?? '0');
  return !isNaN(raw) ? raw : 0;
};

export const isNumber = (input) => {
  return !isNaN(parseFloat(input ?? '0'));
};

export const isPosNumberOrDecimal = (input) => {
  const regex = /^\d+(\.\d+)?$/;
  return regex.test(input);
};

export const isPosNumber = (input) => {
  const regex = /^\d+$/;
  return regex.test(input);
};

export const formatNumberWithCommas = (value) => {
  if (value !== null && value !== undefined) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};

export const formatNumber = (value) => value?.toLocaleString('en-US');

export const convertTo2Decimal = (num) => parseFloat(num).toFixed(2);

export const roundNumber = (value) => {
  const numberValue = parseFloat(value);
  if (!isNaN(numberValue)) {
    return Math.round(numberValue).toString();
  }
  return value;
};

export const generateRandomId = () => {
  return uuidv4();
}